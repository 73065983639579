import axios from '../Utils/axios'

export async function getAdminSettings() {
  const resp = await axios.get(`/campaign-settings/`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function updateAdminSettings(data: any) {
  const resp = await axios.put(`/campaign-settings/update-list`, data)
  return resp.data
}
