import axios from 'Utils/axios'

const CAMPAIGN_ENDPOINT = '/campaigns'
const CAMPAIGN_CHANNEL_ENDPOINT = '/channels'

export async function getCampaignDetail(id) {
  const resp = await axios.get(`${CAMPAIGN_ENDPOINT}/${id}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function deleteCampaign(id) {
  const resp = await axios.delete(`${CAMPAIGN_ENDPOINT}/${id}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
export async function getCampaignFilters() {
  const resp = await axios.get(`${CAMPAIGN_ENDPOINT}/infos/filter`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCampaignChannels() {
  const resp = await axios.get(`${CAMPAIGN_CHANNEL_ENDPOINT}/channels_placements`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function createCampaign({
  name,
  description,
  daily_budget,
  bid_amount,
  kpi,
}: {
  name?: string
  description?: string
  daily_budget?: number
  bid_amount?: number
  kpi?: number
}) {
  const resp = await axios.post(`${CAMPAIGN_ENDPOINT}/`, {
    daily_budget,
    bid_amount,
    kpi,
    name,
    description,
    mkt_obj: 'link_clicks',
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function createCampaignZaloGroup({
  campaign_id,
  zalo_groups,
}: {
  campaign_id: string
  zalo_groups: string[]
}) {
  try {
    const resp = await axios.post(`${CAMPAIGN_ENDPOINT}/${campaign_id}/zalo-groups`, {
      zalo_groups,
    })
    return { data: resp.data }
  } catch (err: any) {
    throw new Error(err.message)
  }
}

export async function updateCampaignZaloGroup({
  campaign_id,
  zalo_groups,
}: {
  campaign_id: string
  zalo_groups: string[]
}) {
  try {
    const resp = await axios.put(`${CAMPAIGN_ENDPOINT}/${campaign_id}/zalo-groups`, {
      zalo_groups,
    })
    return { data: resp.data }
  } catch (err: any) {
    throw new Error(err.message)
  }
}

export async function updateCampaign({
  campaign_id,
  audience_segment_id,
  product_creative_content_id,
  name,
  description,
  daily_budget,
  bid_amount,
  kpi,
}: {
  campaign_id: number
  audience_segment_id?: number
  product_creative_content_id?: number
  name?: string
  description?: string
  daily_budget?: number
  bid_amount?: number
  kpi?: number
}) {
  const resp = await axios.put(`${CAMPAIGN_ENDPOINT}/${campaign_id}`, {
    audience_segment_id,
    product_creative_content_id,
    name,
    description,
    daily_budget,
    bid_amount,
    kpi,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function createContent({
  placement_id,
  is_by_pass_limiter,
  content,
  dynamic_param_dimensions,
}) {
  const resp = await axios.post(
    `/creative-contents/?company=${process.env.REACT_APP_COMPANY_NAME}
  `,
    {
      placement_id,
      is_by_pass_limiter,
      type: 'article',
      content: JSON.stringify(content),
      ...(dynamic_param_dimensions ? { dynamic_param_dimensions } : {}),
    }
  )

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function updateContent({
  content_id,
  content,
  placement_id,
  dynamic_param_dimensions,
  is_by_pass_limiter,
}) {
  const resp = await axios.put(
    `/creative-contents/${content_id}?company=${process.env.REACT_APP_COMPANY_NAME}`,
    {
      content: JSON.stringify(content),
      placement_id,
      ...(dynamic_param_dimensions ? { dynamic_param_dimensions } : {}),
      is_by_pass_limiter,
    }
  )

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function addCampaignContent({ content_id, campaign_id }) {
  const resp = await axios.post(`${CAMPAIGN_ENDPOINT}/${campaign_id}/creative-contents`, {
    product_creative_content_id: content_id,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function addCampaignSchedule({ schedule, campaign_id }) {
  const resp = await axios.post(`${CAMPAIGN_ENDPOINT}/${campaign_id}/schedule/`, {
    ...(schedule.mode === 'recurring'
      ? {
          days_of_week: schedule.days,
          start_at: schedule.start_at,
          end_time: schedule.end_time,
        }
      : {}),
    mode: schedule.mode,
    is_now: false,
    start_time: schedule.start_time,
    published: false,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function updateCampaignSchedule({ schedule, campaign_id }) {
  const resp = await axios.put(`${CAMPAIGN_ENDPOINT}/${campaign_id}/schedule/`, {
    ...(schedule.mode === 'recurring'
      ? {
          days_of_week: schedule.days,
          start_at: schedule.start_at,
          end_time: schedule.end_time,
        }
      : {}),
    mode: schedule.mode,
    is_now: false,
    published: false,
    start_time: schedule.start_time,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function publishCampaign({ campaign_id, audience_id }) {
  const resp = await axios.post(`${CAMPAIGN_ENDPOINT}/${campaign_id}/publish`, {
    audience_segment_id: audience_id,
    mkt_obj_attr: 'article',
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export const getZnsTemplateById = async () => {
  const resp = await axios.get(`/placements/templates/zns`)
  if (!resp.data) {
    throw resp
  }
  return { data: resp.data }
}

export const getParameterSegment = async (id) => {
  const resp = await axios.get(`/audience-segments/${id}/parameters`)
  if (!resp.data) {
    throw resp
  }
  return { data: resp.data }
}
